
<template>
    <div class="row">

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">Late Payment Details</h3>
            <hr class="my-4" />
        </div>

        <div class="table-responsive"
            v-for="(record, index) in records"
            :key="record.id"
        >
            <h4>
                {{index+1}}. <b class="text-uppercase">Late Payment Description</b> -
                <em class="text-danger">{{ record.description }}</em>
            </h4>

            <table class="table table-bordered">                    
                <tbody>
                    <td>
                        <h4>Transaction Number</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.trans_no }}
                        </h4>
                    </td>
                </tbody> 

                <tbody>
                    <td>
                        <h4>Transaction Amount</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.amount | formatPrice }}
                        </h4>
                    </td>
                </tbody> 

                <tbody>
                    <td>
                        <h4>Transaction Date</h4>
                    </td>
                    <td>
                        <h4 class="text-lowercase font-weight-light">
                            {{ getTransactionDate(record.transaction) | formatDate }}
                        </h4>
                    </td>
                </tbody>
                
                <tbody>
                    <td>
                        <h4>RRR</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.transaction.payment_ref }}
                        </h4>
                    </td>
                </tbody> 

                <tbody>
                    <td>
                        <h4>Payment Status</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.transaction.status }}
                        </h4>
                    </td>
                </tbody> 

            </table>

        </div>

        <div class="col-lg-12">
            <hr class="my-4" />
        </div>
    </div> 
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'LatePaymentDetails',
        props: ['records'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }                
            }
        },
        methods: {
            getTransactionDate(trans) {// get trans date
                return trans.date_paid ? trans.date_paid: trans.date_generated;
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
