
<template>
    <div class="row">

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">Appointment Records with Dates</h3>
            <hr class="my-4" />
        </div>

        <div
            class="table-responsive"
            v-if="appointments.length > 0"
        >

            <table class="table table-bordered">

                <thead>
                    <!-- <th>Appointment Type</th> -->
                    <th>Hospital Name</th>
                    <th>Hospital Address</th>
                    <th>Is Current?</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                </thead>

                <tbody
                    v-for="record in appointments"
                    :key="record.id"
                >
                    <td>
                        <h4 class="text-uppercase font-weight-light">
                            <span v-html="breakWord(record.hospital_name)"></span>
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <span v-html="breakWord(record.hospital_address, 60)"></span>
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ getStatus(record.current_status) }} Appointment
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.start_date | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.end_date | formatDate }}
                        </h4>
                    </td>
                </tbody>

            </table>

        </div>

        <div
            v-if="appointments.length == 0"
            class="col-sm-12 text-center"
        >
            <base-alert type="warning">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No appointment records added yet!!!
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <hr class="my-4" />
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'AppointmentRecords',
        props: ['appointments', 'breakWord'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getStatus(status) {
                return (status == 'yes') ? 'Current' : 'Past';
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
