
<template>
    <div>

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">Change Of Name Details</h3>
            <hr class="my-4" />
        </div>

        <div class="row" v-if="record">
            <div class="table-responsive">                    
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.first_name }}
                            </h4>
                        </td>
                        <td>
                            <h4>Last Name (Surname)</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.last_name }}
                            </h4>
                        </td>
                        <td v-if="record.other_name">
                            <h4>Other Names</h4>
                        </td>
                        <td v-if="record.other_name">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.other_name }}
                            </h4>
                        </td>
                    </tbody> 
                </table>

            </div>

            <div class="col-lg-12" v-if="record.documents && record.documents.length > 0">
                <div class="row">
                    <div 
                        class="col-sm-4 text-center borderLine" 
                        v-for="(document, index) in record.documents"
                        :key="index"
                    >      
                        <br>
                        <h4 class="text-capitalize height-50"
                            v-if="document.hasFile"
                        >
                            {{ document.name }}
                        </h4>
                        <h4 class="text-capitalize height-50"
                            v-if="! document.hasFile"
                        >
                            {{ document.name }}
                        </h4>
                        <br> 
                        <badge
                            v-if="! document.hasFile"
                            type="danger" 
                        >
                            Not Uploaded
                        </badge> 
                        <base-button 
                            tag="a" type="primary" 
                            size="sm" 
                            v-if="document.hasFile"
                            :href="document.url"
                            class="active" role="button" 
                            aria-pressed="true"
                            target="_blank"
                        >
                            <i class="fa fa-eye"></i> View File
                        </base-button>          
                    </div>
                </div>
            </div>
        </div> 

        <div class="col-lg-12 text-center" v-if="! record">
            <base-alert type="danger">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No Change Of Name Details, Kindly reject application for Doctor to re-apply
                </span>
            </base-alert>  
        </div>
        <hr class="my-4" />

    </div>
</template>
<script>
    export default {
        name: 'ChangeOfName',
        props: ['record'],
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
