
<template>
    <div class="row">

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">Payment Details</h3>
            <hr class="my-4" />
        </div>

        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <th>Transaction Number</th>
                    <th>Amount</th>
                    <th>Transaction Date</th>
                    <th>RRR</th>
                    <th>Payment Status</th>
                </thead>
                <tbody>
                    <td>
                        <h4 class="font-weight-light">
                            {{ transaction.trans_no }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ transaction.amount | formatPrice }} {{ currency }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-lowercase font-weight-light">
                            {{ getTransactionDate(transaction) | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ transaction.payment_ref }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ transaction.status }}
                        </h4>
                    </td>
                </tbody>
            </table>
        </div>

        <div class="col-lg-12" v-if="nmaRecords && nmaRecords.length > 0">
            <hr class="my-4" />
            <h3 class="mb-0 text-uppercase">NMA LEVY Payment Details</h3>
            <hr class="my-4" />
        </div>

        <div class="table-responsive"
            v-if="nmaRecords && nmaRecords.length > 0"
        >
            <table class="table table-bordered">
                <thead>
                    <th>S/N</th>
                    <th>Transaction Number</th>
                    <th>Amount</th>
                    <th>Transaction Date</th>
                    <th>RRR</th>
                    <th>Payment Status</th>
                </thead>

                <tbody
                    v-for="(record, index) in nmaRecords"
                    :key="record.id"
                    v-show="record.transaction"
                >
                    <td>
                        <h4 class="font-weight-light">
                            {{ index+1 }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.transaction.trans_no }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.transaction.amount | formatPrice }} NGN
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-lowercase font-weight-light">
                            {{ getTransactionDate(record.transaction) | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.transaction.payment_ref }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.transaction.status }}
                        </h4>
                    </td>
                </tbody>
            </table>
        </div>

        <div class="col-lg-12">
            <hr class="my-4" />
        </div>

    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'PaymentDetails',
        props: ['transaction', 'nma'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            nmaRecords() {
                let items = [];
                if(this.nma && this.nma.records) {
                    items = this.nma.records;
                }
                return items;
            },
            currency() {
                return this.transaction && this.transaction.currency ? this.transaction.currency : 'NGN';
            }
        },
        methods: {
            getTransactionDate(trans) {// get trans date
                return trans.date_paid ? trans.date_paid: trans.date_generated;
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
