<template>
    <div class="container">
        <div class="row">

            <div 
                class="col-lg-12 text-center font-weight-800" 
                v-if="application && application.is_sanctioned"
            >
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                    <span class="alert-inner--text">
                    Doctor has been sanctioned
                    </span>
                </base-alert>
                <br>
            </div>
            <div 
                class="col-lg-12 text-center font-weight-800" 
                v-if="application && application.is_deceased"
            >
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                    <span class="alert-inner--text">
                        Doctor is Deceased
                    </span>
                </base-alert>
                <br>
            </div>

            <div 
                class="col-lg-12 text-center font-weight-800" 
                v-if="application && application.user_data && application.user_data.user 
                        && (application.user_data.user.status == 'suspend')"
            >
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                    <span class="alert-inner--text">
                        Doctor has been Suspended
                    </span>
                </base-alert>
                <br>
            </div>

            <div class="col-lg-2">
                <h3 class="mb-0 text-uppercase">Basic Details</h3>
            </div>



            <div class="col-lg-10" v-if="showLicenseEditButton">

                <form
                    :action="printCertificateUrl"
                    method="POST" target="_blank" class="right"
                    v-if="application.license_certificate && application.license_certificate.aq_certificate
                        && application.license_certificate.aq_certificate.aq
                        && application.license_certificate.aq_certificate.aq.status"
                >
                    <input
                        type="hidden" name="id"
                        :value="application.license_certificate.aq_certificate.aq.id"
                    />
                    <input
                        type="hidden" name="cert_id"
                        :value="application.license_certificate.aq_certificate.certificate.id"
                    />
                    <input
                        type="hidden" name="admin_id"
                        :value="user.id"
                    />

                    <base-button
                        v-if="(application.license_certificate.aq_certificate.aq.status == 'approved')"
                        size="sm"
                        type="warning"
                        nativeType="submit"
                        :disabled="privileges && privileges.view == 'no'"
                    >
                        <i class="fa fa-print"></i>
                        Re-Print AQ Certificate
                    </base-button>

                    <router-link
                        v-if="(application.license_certificate.aq_certificate.aq.status == 'pending')
                            && application.license_certificate.aq_certificate.aq_app_type
                            && application.license_certificate.aq_certificate.aq_app_type.code"
                        class="btn btn-sm btn-warning"
                        :to="'/applications/' + application.license_certificate.aq_certificate.aq_app_type.code +
                            '/' + application.license_certificate.aq_certificate.aq.id"
                    >
                        <i class="fa fa-pen"></i>
                        Approve AQ Now
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                </form>

                <form
                    :action="printCertificateUrl"
                    method="POST" target="_blank" class="right"
                    v-show="application.license_certificate && application.license_certificate.annual"
                    v-for="annual in application.license_certificate.annual"
                    :key="annual.id && (annual.status == 'approved')"
                >
                    <input
                        type="hidden" name="id"
                        :value="annual.id"
                    />
                    <input
                        type="hidden" name="cert_id"
                        :value="application.license_certificate.certificate.id"
                    />
                    <input
                        type="hidden" name="admin_id"
                        :value="user.id"
                    />

                    <base-button
                        size="sm"
                        type="dark"
                        nativeType="submit"
                        :disabled="privileges && privileges.view == 'no'"
                    >
                        <i class="fa fa-print"></i>
                        Re-Print License {{ ' (' + annual.license_year + ')' }}
                    </base-button>
                    <br>
                    <base-button
                        type="success" size="sm" style="margin-top: 5px;"
                        @click="updateLicenseYear(annual, 'license')"
                        :disabled="privileges && privileges.update == 'no'"
                    >
                        <i class="fa fa-pen"></i>
                        Edit License Year {{ ' (' + annual.license_year + ')' }}
                    </base-button>
                </form>

                <form
                    :action="printCertificateUrl"
                    method="POST" target="_blank" class="right"
                    v-if="application.certificate"
                >
                    <input
                        type="hidden" name="id"
                        :value="application.id"
                    />
                    <input
                        type="hidden" name="cert_id"
                        :value="application.certificate.id"
                    />
                    <input
                        type="hidden" name="admin_id"
                        :value="user.id"
                    />

                    <base-button
                        size="sm"
                        type="info"
                        nativeType="submit"
                        :disabled="privileges && privileges.view == 'no'"
                    >
                        <i class="fa fa-print"></i>
                        Re-Print Certificate
                    </base-button>
                    <br>
                    <base-button
                        type="primary" size="sm"
                        style="margin-top: 5px; margin-right: 5px;"
                        @click="updateLicenseYear(application, 'certificate')"
                        :disabled="privileges && privileges.update == 'no'"
                        v-if="! isLicenseApp && ! isDiasporaFullReg"
                    >
                        <i class="fa fa-pen"></i> Edit Cert Expiry Year
                    </base-button>

                    <base-button
                        type="success" size="sm" style="margin-top: 5px;"
                        @click="updateLicenseYear(application, 'license')"
                        :disabled="privileges && privileges.update == 'no'"
                        v-if="isLicenseApp"
                    >
                        <i class="fa fa-pen"></i>
                        Edit License Year {{ ' (' + application.license_year + ')' }}
                    </base-button>
                    &nbsp;&nbsp;&nbsp;
                </form>
            </div>

            <!-- this is for only COGS application only -->
            <div class="col-lg-10" v-if="showCogEditButton">
                <base-button
                    type="primary" size="sm"
                    style="margin-top: 5px;" class="right"
                    @click="editProvFullRegNo(application)"
                    :disabled="privileges && privileges.update == 'no'"
                    v-if="! isLicenseApp"
                >
                    <i class="fa fa-pen"></i> Edit Prov. &#38; Full Reg No
                </base-button>
            </div>


            <!-- this is for only ctc full reg application only -->
            <div class="col-lg-10" v-if="showCtcDateButton">

                <form
                    :action="printCertificateUrl"
                    method="POST" target="_blank" class="right"
                    v-show="application && application.certificate"
                >
                    <input
                        type="hidden" name="id"
                        :value="application.id"
                    />
                    <input
                        type="hidden" name="cert_id"
                        :value="application.certificate.id"
                    />
                    <input
                        type="hidden" name="admin_id"
                        :value="user.id"
                    />

                    <base-button
                        size="sm"
                        type="dark"
                        nativeType="submit"
                        :disabled="privileges && privileges.view == 'no'"
                    >
                        <i class="fa fa-print"></i>
                        Re-Print Certificate
                    </base-button>

                    <base-button
                        type="primary" size="sm"
                        class="right"
                        @click="updateCtcFullRegDate(application)"
                        :disabled="privileges && privileges.update == 'no'"
                        v-if="! isLicenseApp"
                    >
                        <i class="fa fa-pen"></i> Edit Full Reg Date
                    </base-button>
                </form>
            </div>
            <!-- ends here -->

            <!-- this is for only ctc full reg application only -->
            <div class="col-lg-10" v-if="showAQCertButton">

                <form
                    :action="printCertificateUrl"
                    method="POST" target="_blank" class="right"
                    v-show="application && application.certificate"
                >
                    <input
                        type="hidden" name="id"
                        :value="application.id"
                    />
                    <input
                        type="hidden" name="cert_id"
                        :value="application.certificate.id"
                    />
                    <input
                        type="hidden" name="admin_id"
                        :value="user.id"
                    />

                    <base-button
                        size="sm"
                        type="primary"
                        nativeType="submit"
                        :disabled="privileges && privileges.view == 'no'"
                    >
                        <i class="fa fa-print"></i>
                        Re-Print Certificate
                    </base-button>
                </form>
            </div>
            <!-- ends here -->

            <!-- this is for only diaspora license application only -->
            <div class="col-lg-10" v-if="showDiasporaEvidenceButton">

                <form
                    :action="printCertificateUrl"
                    method="POST" target="_blank" class="right"
                    v-show="application && application.certificate"
                >
                    <input type="hidden" name="id" :value="application.id" />
                    <input type="hidden" name="cert_id" :value="application.certificate.id" />
                    <input type="hidden" name="admin_id" :value="user.id" />

                    <base-button
                        size="sm"
                        type="primary"
                        nativeType="submit"
                        :disabled="privileges && privileges.view == 'no'"
                    >
                        <i class="fa fa-print"></i>
                        Print Evidence
                    </base-button>
                </form>
            </div>
            <!-- ends here -->


            <div class="col-lg-12">
                <hr class="my-4" />
            </div>

            <div class="col-lg-8" v-if="application">

                <h3>
                    Application Date - &nbsp;&nbsp;&nbsp;
                    {{ application.application_date | formatDate }}
                </h3>

                <h3>
                    Application Status - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        :type="getStatusColor(application.status)"
                    >
                        {{ application.status }}
                    </badge>
                </h3>

                <h3>
                    Insurance Indemnity - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        :type="getInsuranceStatusColor(application.insurance)"
                    >
                        {{ getInsuranceStatus(application.insurance) }}
                    </badge>
                </h3>

                <h3>
                    Payment Status - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        :type="getPaymentStatusColor(application.payment_status)"
                    >
                        {{ application.payment_status }}
                    </badge>
                </h3>

                <!-- <h3 v-if="application.nma_levy">
                    NMA Levy Status - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        :type="getNmaStatusColor(application.nma_levy.status)"
                    >
                        {{ getNmaStatus(application.nma_levy.status) }}
                    </badge>
                </h3> -->

                <h3 v-if="! isProvisionalApp">
                    Full Registration No - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        type="primary"
                    >
                        {{ application.user_data && application.user_data.registration_no
                            ? application.user_data.registration_no : 'N/A' }}
                    </badge>
                </h3>

                <h3 v-if="application.user_data && application.user_data.provisional_reg_no">
                    Provisional Registration No - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        type="primary"
                    >
                        {{ application.user_data.provisional_reg_no  }}
                    </badge>
                </h3>

                <h3>
                    Late Payment - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        :type="(application.surcharge == 'yes') ? 'success' : 'warning'"
                    >
                        {{ application.surcharge }}
                    </badge>
                </h3>

                <h3 v-if="application.is_sanctioned">
                    Sanctioned Status - &nbsp;&nbsp;&nbsp;
                    <badge size="sm"
                        type="danger"
                    >
                        On Sanction
                    </badge>
                </h3>
                <br>

            </div>

            <div class="col-lg-4">
                <div class="card-profile-image marginTop40">
                    <img
                        :src="getUserPix(application.user_data)"
                        class="rounded-circle"
                        @error="replaceByDefault"
                    >
                </div>
                <br><br><br><br><br>
            </div>

            <div class="col-lg-12 text-center font-weight-800" v-if="application.is_deceased">
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                    <span class="alert-inner--text">
                        Doctor is Deceased
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'BasicDetails',
        props: ['application', 'privileges', 'loadApplication', 'isProvisionalApp', 'isDiasporaFullReg'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl', 'blankImageUrl', 'printCertificateUrl', 'user']),
            showLicenseEditButton() {
                let type = this.application.application_type;
                return (this.application.status == 'approved') && type && ((type.code == 'annual-license') || (type.code == 'annual-license-old') 
                    || (type.code == 'alt-annual-license') || (this.application.license_certificate && this.application.license_certificate.annual) 
                    || this.isDiasporaFullReg) ? true : false;
            },
            showCtcDateButton() {
                let type = this.application.application_type;
                return (this.application.status == 'approved') && type &&
                        (type.code == 'ctc-full-reg') ? true : false;
            },
            showCogEditButton() {
                let type = this.application.application_type;
                return (this.application.status == 'approved') && type &&
                        (type.code == 'cert-good-standing') ? true : false;
            },
            showAQCertButton() {
                let type = this.application.application_type;
                return (this.application.status == 'approved') && type && ((type.code == 'additional-pgq') 
                            || (type.code == 'additional-pgq-diaspora')) ? true : false;
            },
            isLicenseApp() {
                let type = this.application.application_type;
                return (type.code == 'annual-license') || (type.code == 'annual-license-old')
                    || (type.code == 'alt-annual-license');
            },
            showDiasporaEvidenceButton() {
                let type = this.application.application_type;
                return (this.application.status == 'approved') && type &&
                        (type.code == 'diaspora-license') ? true : false;
            },
        },
        methods: {
            ...mapActions(['updateUserLicenseYear']),
            updateLicenseYear(app, type) {                
                let data;
                let currentDate = (type == 'license') ? (app.license_year + '-12-31') : app.valid_date;
                console.log(currentDate, type)
                swal.fire({
                    title: 'Select '+ type +' expiry date',
                    html: '<input class="swal2-input" id="app-date" type="date" value="'+ currentDate +'">',
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let date = $('#app-date').val();
                        if(type == 'license') {
                            let splitted = date.split('-');
                            data = { 'id' : app.id, 'license_year' : splitted[0], 'valid_date' : date };
                        } else {
                            data = { 'id' : app.id, 'valid_date' : date };
                        }
                        data.type = type + ' year';
                        return this.updateUserLicenseYear(data).then((response) => {//update
                            this.loadApplication();// refresh page
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Record Updated!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadApplication();// refresh page
                    }
                });
            },
            updateCtcFullRegDate(app) {
                let currentDate = app && app.ctc_full_reg_date ? app.ctc_full_reg_date : null;
                swal.fire({
                    title: 'Enter Ctc Full Reg. Date',
                    html: '<input class="swal2-input" id="reg-date" type="date" value="'+ currentDate +'">',
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {// eslint-disable-next-line
                        let date = $('#reg-date').val();
                        let data = { 'id' : app.id, 'ctc_full_reg_date' : date, type : 'Full Reg Date' };
                        return this.updateUserLicenseYear(data).then((response) => {//update
                            this.loadApplication();// refresh page
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Record Updated!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadApplication();// refresh page
                    }
                });
            },
            editProvFullRegNo(app) {// edit COGS details
                let inputs = app.has_good_standing;
                swal.fire({
                    title: 'Update COGs Details',
                    html:
                        '<label>Enter Full Reg. Date</label>'+
                        '<input class="swal2-input" id="full-reg-date" type="date" value="'+ inputs.full_reg_date +'">'+
                        '<label>Enter Full Reg. Number</label><br>'+
                        '<input class="swal2-input" id="full-reg-no" type="number" value="'+ inputs.full_reg_number +'">'+
                        '<label>Enter Provisional Reg. Date</label>'+
                        '<input class="swal2-input" id="prov-reg-date" type="date" value="'+ inputs.prov_reg_date +'">'+
                        '<label>Enter Provisional Reg. Number</label><br>'+
                        '<input class="swal2-input" id="prov-reg-no" type="number" value="'+ inputs.prov_reg_number +'">',
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {// eslint-disable-next-line
                        let fullDate = $('#full-reg-date').val();// eslint-disable-next-line
                        let fullNo = $('#full-reg-no').val();// eslint-disable-next-line
                        let provDate = $('#prov-reg-date').val();// eslint-disable-next-line
                        let provNo = $('#prov-reg-no').val();
                        if(fullDate && fullNo && provDate && provNo) {// update
                            let data = { id : inputs.id, full_reg_date : fullDate, full_reg_number : fullNo,
                                    prov_reg_date : provDate, prov_reg_number : provNo, type : 'cogs' };
                            return this.updateUserLicenseYear(data).then((response) => {//update
                                this.loadApplication();// refresh page
                                if (!response.status) {
                                throw new Error(response.msg)
                                }
                                return response
                            }).
                            catch((error) => {
                                swal.showValidationMessage(
                                `Request failed: ${error}`
                                )
                            });
                        } else {
                            swal.showValidationMessage(
                                `Request failed: Some Field(s) are empty, try again`
                            )
                        }
                },
                allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Record Updated!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadApplication();// refresh page
                    }
                });
            },
            years() {
                let endYear = moment().format('YYYY') -32;// start from 1900
                let currentYear = this.application.license_year ? this.application.license_year
                                    : parseInt(moment().format('YYYY'));
                let startYear = parseInt(moment().format('YYYY')) + 20;
                let years = {};
                years[currentYear] = currentYear;// add current year first
                for (let i = startYear; i >= endYear; i--) {
                    if(i != currentYear) {
                        years[i] = i;
                    }
                }
                return years;
            },
            getStatusColor(value) {
                if(value == 'approved') {
                    return 'success';
                } else if(value == 'pending') {
                    return 'warning';
                } else {
                    return 'danger'
                }
            },
            getPaymentStatusColor(value) {
                return (value == 'paid') ? 'success' : 'danger';
            },
            getInsuranceStatusColor(data) {
                let status = this.getInsuranceStatus(data);
                return (status == 'valid') ? 'success' : 'danger';
            },
            getNmaStatusColor(value) {
                return value ? 'success' : 'danger';
            },
            getNmaStatus(value) {
                return value ? 'PAID' : 'NOT-PAID';
            },
            getInsuranceStatus(data) {
                if(data) {
                    let status = moment(data.expiry_date).isAfter(moment(), 'day');
                    return status ? 'valid' : 'expired';
                } else {
                    return 'expired';
                }
            },
            getUserPix(data) {// get user photo url
                return data ? this.userPhotoUrl+data.user_id+'/photo/'+data.photo : null;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            }        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
    .right {
        float: right;
    }
    .marginLeft5 {
        margin-left: 5px;
    }
</style>
<style>
    .swal2-input {
        height: 2.625em;
        padding: 0 .75em;
        margin-top: -5px !important;
    }
    .swal2-input[type=number] {
        max-width: 100% !important;
    }
</style>
