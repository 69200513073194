
<template>
    <div class="row">

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">CPD Documents</h3>
            <hr class="my-4" />
        </div>

        <div 
            class="table-responsive"
            v-if="points.length > 0"
        >
            
            <table class="table table-bordered">

                <thead>
                    <th>DESCRIPTION</th>
                    <th>LICENSE YEAR</th>
                    <th>DATE</th>
                    <th>POINTS</th>
                    <th>&nbsp;</th>
                </thead>

                <tbody
                    v-for="record in points"
                    :key="record.id" 
                >
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.description }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.license_year }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.date | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.point ? record.point : 'Not Allocated' }}
                        </h4>
                    </td>
                    <td>
                        <base-button tag="a" 
                            type="primary" size="sm" 
                            :href="getCpdPointUrl(record.file_name)" 
                            role="button" target="_blank"
                            aria-pressed="true"
                            :disabled="privileges.view == 'no'"
                        >
                        <i class="fa fa-eye"></i> View File                          
                        </base-button>

                        <base-button 
                            type="success" size="sm"
                            @click="updateCpdPoint(record)"
                            :disabled="privileges.update == 'no'"
                        >
                            <i class="fa fa-check"></i> 
                            {{ record.point ? 'Update' : 'Allocate' }}
                        </base-button>
                    </td>
                </tbody>  

            </table>

        </div>

        <div 
            v-if="points.length == 0"
            class="col-sm-12 text-center"
        >
            <base-alert type="warning">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No CPD Documents have not been uploaded yet!!!
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <hr class="my-4" />
        </div>
    </div> 
</template>
<script>
    import moment from 'moment';
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'CpdPoints',
        props: ['points', 'userId', 'privileges', 'loadApplication'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl'])
        },
        methods: {
            ...mapActions(['updateUserCpdPoint']),
            getCpdPointUrl(name) {
                return name ? this.userPhotoUrl+this.userId+'/documents/cpd/'+name : null;
            }, 
            updateCpdPoint(point) {// update CPD point
                swal.fire({
                title: 'Allocate point to '+ point.description +'\'s CPD Document',
                input: 'number',
                inputValue: point.point,
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Allocate',
                showLoaderOnConfirm: true,
                preConfirm: (data) => {// save CPD point
                    this.preloader(); // show loading
                    this.updateUserCpdPoint({'id' : point.id, 'point' : data}).then((res) => {
                        if(res.status) {
                            swal.fire('Point Allocated!', res.msg, 'success');
                            this.loadApplication();// reload page
                        } else {
                            swal.fire('Error Occurred!', res.msg, 'error');
                        }
                    }).
                    catch(() => {
                        let msg = 'Server Failure!, Kindly reload the page...';
                        swal.fire('Error Occurred!', msg, 'error');
                    });
                },
                    allowOutsideClick: () => !swal.isLoading()
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
