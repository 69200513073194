
<template>
    <div class="row">

        <div class="col-lg-12"> 
            <h3 class="mb-0 text-uppercase">Internship Documents</h3>
            <hr class="my-4" />
        </div>

        <div 
            class="col-lg-12" 
            v-if="record && record.documents && record.documents.length > 0"
        >
            <div class="row">
                <div 
                    class="col-sm-6 text-center borderLine" 
                    v-for="(document, index) in record.documents"
                    :key="index"
                >      
                    <br>
                    <h4 class="text-capitalize height-50"> 
                        {{document.name ? document.name : 'No Document Uploaded'}}
                    </h4><br>  
                    <base-button 
                        tag="a" type="primary" 
                        size="sm" 
                        v-if="document.hasFile"
                        :href="document.url"
                        class="active" role="button" 
                        aria-pressed="true"
                        target="_blank"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>  

                    <badge size="sm" 
                        type="danger"
                        v-if="! document.hasFile"
                    >
                        No Document Uploaded
                    </badge>        
                </div>
            </div>
        </div>

        <div 
            class="table-responsive"
            v-if="record && record.documents && record.documents.length > 0"
        >
            <br>
            <table class="table table-bordered">
                <thead>
                    <th>Internship Start Date</th>
                    <th>Internship End Date</th>
                </thead>

                <tbody>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.start_date | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.end_date | formatDate }}
                        </h4>
                    </td>
                </tbody> 
            </table>

        </div>

        <div 
            v-if="record && record.documents && record.documents.length == 0"
            class="col-sm-12 text-center"
        >
            <base-alert type="warning">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No documents added yet!!!
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <hr class="my-4" />
        </div>
    </div> 
</template>
<script>
    import { mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'InternshipDocuments',
        props: ['userId'],
        data() {
            return {
                record: null,
                isLoadingBlade: true
            }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            ...mapActions(['getInternshipRecords']),
            loadInternshipRecords() {// load Application
                this.getInternshipRecords({'id' : this.userId}).then((res) => {// get records
                    this.record = res;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getStatus(status) {
                return (status == 'yes') ? 'Current' : 'Past';
            }
        },
        mounted() {
            this.loadInternshipRecords(); // load internship records
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
