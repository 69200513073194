
<template>
    <div>
        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">Additional Records</h3>
            <hr class="my-4" />
        </div>

        <div class="row" v-if="hasAdditionalRecords && record.has_additional_record">
            <div class="table-responsive">                    
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>First day of entry into Nigeria</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.first_entry_day | formatDate }}
                            </h4>
                        </td>
                        <td>
                            <h4>Date of departure (out of Nigeria) </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.departure_day | formatDate }}
                            </h4>
                        </td>
                    </tbody> 

                    <tbody>
                        <td>
                            <h4>Duration of stay in Nigeria (Months)</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.duration_of_stay }}
                            </h4>
                        </td>
                        <td>
                            <h4>Purpose of entry into Nigeria</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.purpose_of_entry }}
                            </h4>
                        </td>
                    </tbody> 

                    <tbody>
                        <td colspan="3">
                            <h4>
                                Have you ever been the subject of an investigation
                                or disciplinary action for <br>professional misconduct in
                                Country of Practice or  Jurisdiction you have worked?
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.disciplinary }}
                            </h4>
                        </td>
                    </tbody>

                    <!-- <tbody>
                        <td>
                            <h4>Have you been previously registered in Nigeria?</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.previousely_registered }}
                            </h4>
                        </td>
                        <td>
                            <h4>Have you left Nigeria since previous Registraton?</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_additional_record.left_nigeria }}
                            </h4>
                        </td>
                    </tbody> -->
                </table>
                
                <div v-if="record.has_jurisdictions && record.has_jurisdictions.length > 0">
                    <hr class="my-4" />

                    <h4 class="text-center text-danger text-uppercase">
                        THE NAMES OF MEDICAL COUNCILS OR JURISDICTION UNDER WHICH Doctor HAVE PREVIOUSLY WORKED:
                    </h4>

                    <table class="table table-bordered">
                        <thead>
                            <th>S/N</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Period</th>
                            <th>Reg Number</th>
                        </thead>
                        <tbody 
                            v-for="(jurisdiction, index) in record.has_jurisdictions"
                            :key="jurisdiction.id"
                        >
                            <td>
                                <h4>{{ index+1 }}</h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ jurisdiction.name }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ jurisdiction.address }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ jurisdiction.period }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ jurisdiction.reg_no }}
                                </h4>
                            </td>
                        </tbody> 
                    </table>
                </div>

                <div v-if="record.has_referees && record.has_referees.length > 0">
                    <hr class="my-4" />

                    <h4 class="text-center text-danger text-uppercase">
                        Doctor'S referees INFORMATION BELOW
                    </h4>

                    <table class="table table-bordered">
                        <thead>
                            <th>S/N</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Phone Number</th>
                            <th>Occupation</th>
                        </thead>
                        <tbody 
                            v-for="(referee, index) in record.has_referees"
                            :key="referee.id"
                        >
                            <td>
                                <h4>{{ index+1 }}</h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ referee.name }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ referee.address }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ referee.phone }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ referee.occupation }}
                                </h4>
                            </td>
                        </tbody> 
                    </table>

                        <hr class="my-4" />
                </div>

            </div>
        </div>

        <div class="col-lg-12 text-center" v-if="! hasAdditionalRecords">
            <base-alert type="danger">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No Additional Records, Kindly reject application for Doctor to re-apply
                </span>
            </base-alert>  
        </div>
        <hr class="my-4" />
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'AdditionalRecords',
        props: ['record'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            hasAdditionalRecords() {  
                let data = this.record.has_additional_record;
                let jurisdictions = this.record.has_jurisdictions ? this.record.has_jurisdictions.length > 0 : false;
                let referees = this.record.has_referees ? this.record.has_referees.length > 0 : false;
                return data && jurisdictions && referees;
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
