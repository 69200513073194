import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=7021cc70&scoped=true&"
import script from "./Documents.vue?vue&type=script&lang=js&"
export * from "./Documents.vue?vue&type=script&lang=js&"
import style0 from "./Documents.vue?vue&type=style&index=0&id=7021cc70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7021cc70",
  null
  
)

export default component.exports