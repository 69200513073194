
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ replaceString(code) }}</h3>
                                </div>
                            </div>
                        </div>

                        <!-- import Unit template here -->
                        <Unit
                            :privileges="privileges"
                            v-if="code && code == 'units'"
                        />

                        <!-- import Department template here -->
                        <Department
                            :privileges="privileges"
                            v-if="code && code == 'departments'"
                        />

                        <!-- import Users template here -->
                        <Users
                            :privileges="privileges"
                            v-if="code && code == 'user-accounts'"
                        />

                        <!-- import Payment Settings template here -->
                        <PaymentSettings
                            :privileges="privileges"
                            v-if="code && code == 'payment-settings'"
                        />

                        <!-- import Email Settings template here -->
                        <EmailSettings
                            :privileges="privileges"
                            v-if="code && code == 'email-settings'"
                        />

                        <!-- import Specialization Settings template here -->
                        <SpecializationSettings
                            :privileges="privileges"
                            v-if="code && code == 'specialization-settings'"
                        />

                        <!-- import AddQualification Settings template here -->
                        <AddQualificationSettings
                            :privileges="privileges"
                            v-if="code && code == 'additional-qualification-settings'"
                        />

                        <!-- import Specialization Settings template here -->
                        <CountriesStatesSettings
                            :privileges="privileges"
                            v-if="code && code == 'countries-states-settings'"
                        />

                        <!-- import Posting Settings template here -->
                        <Posting
                            :privileges="privileges"
                            v-if="code && code == 'posting-settings'"
                        />

                        <!-- import Parameters Settings template here -->
                        <Parameters
                            :privileges="privileges"
                            v-if="code && code == 'parameters-settings'"
                        />

                        <!-- import Letter Settings template here -->
                        <LetterSettings
                            :privileges="privileges"
                            v-if="code && code == 'letter-config-settings'"
                        />

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import Unit from './Unit/Unit.vue';
    import Department from './Department/Department.vue';
    import Users from './Users/Users.vue';
    import PaymentSettings from './OtherSettings/Payment/PaymentSettings.vue';
    import EmailSettings from './OtherSettings/Email/EmailSettings.vue';
    import SpecializationSettings from './OtherSettings/Specialization/SpecializationSettings.vue';
    import AddQualificationSettings from './OtherSettings/AddQualification/AddQualificationSettings.vue';
    import CountriesStatesSettings from './OtherSettings/Countries/CountriesStatesSettings.vue';
    import Posting from './OtherSettings/Posting/Posting.vue';
    import Parameters from './OtherSettings/Parameters/Parameters.vue';
    import LetterSettings from './OtherSettings/LetterSettings/LetterSettings.vue';

    export default {
        name: 'Settings',
        components: {
            Unit,
            Department,
            Users,
            PaymentSettings,
            EmailSettings,
            SpecializationSettings,
            AddQualificationSettings,
            CountriesStatesSettings,
            Posting,
            Parameters,
            LetterSettings
        },
        data() {
        return {
            code: this.$route.params.code,
        }
        },
        computed: {
            ...mapGetters(['menus', 'user']),
            isHod() {
                return this.user && (this.user.is_hod == 'yes');
            },
            privileges() {
            let privileges = null;
                if(this.menus && this.menus.length > 0) {
                    for(let menu of this.menus) {
                        if(menu.has_child && menu.has_child.length > 0) {
                            for(let child of menu.has_child) {
                                if('/'+child.link == this.$route.path) {
                                    privileges = this.formatPrivileges(child.privileges);
                                }
                            }
                        }
                    }
                }
                return privileges;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile']),
            replaceString(value) {
                let text = value.replace('-', ' ');
                return value ? text.replace('-', ' ') : 'Reporting';
            },
            formatPrivileges(privileges) {
                if(this.isHod) {
                    return  { view : 'yes', add : 'yes', update : 'yes', delete : 'yes' };
                } else {
                    return privileges
                }
            }   
        },
        created() {
            this.fetchUserProfile();
        }
    };
</script>
<style scoped>
</style>
