
<template>
    <tbody>
        <td width="30%">
            &nbsp;
        </td>
        <td>
            <base-button 
                size="lg"
                type="info" 
                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                @click="rejectUserApplication(application.id)"
                v-if="! showBackButton"
            >
                <i class="fa fa-ban"></i> 
                Reject Application
            </base-button>

            <base-button 
                size="lg"
                type="danger" 
                class="right"
                @click="$emit('hideApprovePage')"
                v-if="! showBackButton"
            >
                <i class="fa fa-times"></i> 
                Cancel Reject
            </base-button>

            <base-button 
                size="lg"
                type="danger" 
                @click="$emit('hideApprovePage')"
                v-if="showBackButton"
            >
                <i class="fa fa-reply"></i> 
                Back To Application
            </base-button>
        </td> 
    </tbody>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert
  import moment from 'moment';

  export default {
    name: 'DiasporaLicenseReject',
    props: ['application', 'privileges', 'loadApplication', 'reject_reason'],
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        showBackButton: false
      }
    },
    computed: {
        ...mapGetters(['user']),
        isDocumentReject() {// check if it is document reject
            return this.application && (this.application.payment_status == 'not-paid') && this.application.has_diaspora_license 
                    && (this.application.has_diaspora_license.status == "submitted");
        }
    },
    methods: {
      ...mapActions(['approveDiasporaLicenseApplication']),
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        rejectUserApplication(id) {// reject application
            if(this.reject_reason) {// reject application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRejectApiCall(id);// make api call
                    }
                });
            } else {
                return swal.fire('Invalid Input', 'Enter a valid reason for rejecting the application', 'warning');
            }             
        },
        makeRejectApiCall(id) {// reject application api
            let data = { id : id, reject_reason : this.reject_reason, status : 'rejected', admin_id : this.user.id, is_document: this.isDocumentReject,
                            approved_date : moment().format('YYYY-MM-DD'),  license_id : this.application.has_diaspora_license.id }; 
            this.preloader();// show loading
            this.approveDiasporaLicenseApplication(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Rejected!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });          
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }   
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
