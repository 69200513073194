
<template>
    <div>

        <div class="table-responsive">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>Applicant's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.user_data ? getFullName(application.user_data) : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Applicant's Email Address:</h4>
                    </td>
                    <td>
                        <h4 class="text-lowercase font-weight-light">
                            {{ application.user_data && application.user_data.user ?
                                application.user_data.user.email : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody v-if="application.user_data && application.user_data.folio_number">
                    <td width="30%">
                        <h4>Folio Number:</h4>
                    </td>
                    <td>
                        <h4 class="text-uppercase font-weight-light">
                            {{ application.user_data && application.user_data.folio_number
                                    ? application.user_data.folio_number : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Application Type:</h4>
                    </td>
                    <td>
                        <h4 class="text-uppercase font-weight-light">
                            {{ application.application_type ? application.application_type.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

            </table>
        </div>

        <!-- import Temp reg approval template here -->
        <TempRegApproval
            v-if="((code == 'limited-reg') || (code == 'limited-reg-long') || (code == 'limited-reg-long-renewal')
                    || (code == 'limited-reg-renewal')) && application && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-bind:expiryDate="expiryDate"
            v-on:hideApprovePage="hideApprovePage"
        />

        <!-- import Provisional reg approval template here -->
        <ProvisionalApproval
            v-if="isProvisionalApp && showOtherApprovalPage"
            :application="application"
            :privileges="privileges"
            :loadApplication="loadApplication"
            :expiryDate="expiryDate"
            :isProvisionalApp="isProvisionalApp"
            v-on:hideApprovePage="hideApprovePage"
        />


        <!-- import change of name approval template here -->
        <ChangeOfNameApproval
            v-if="((code == 'change-of-name') || (code == 'change-of-name-diaspora')) && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />


        <!-- import AQ approval template here -->
        <AddQualificationApproval
            v-if="((code == 'additional-pgq') || (code == 'additional-pgq-diaspora')) && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />


        <!-- import LOGs approval template here -->
        <GoodStandingApproval
            v-if="code == 'cert-good-standing' && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />


        <!-- import change of name approval template here -->
        <LifeLicenseApproval
            v-if="code == 'life-license' && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />


        <!-- import ecis verification approval template here -->
        <EcisApproval
            v-if="code == 'eics-verification' && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />

        <!-- import ctc full reg approval template here -->
        <CtcFullRegApproval
            v-if="code == 'ctc-full-reg' && showOtherApprovalPage"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />

        <!-- import full without Prov AQ reg approval template here --> 
        <FullWithOutAQRegApproval
            v-if="((code == 'full-aq-reg') || (code == 'full-aq-diaspora')) && showOtherApprovalPage"
            :application="application"
            :privileges="privileges"
            :loadApplication="loadApplication"
            :expiryDate="expiryDate"
            :isDiasporaFullReg="isDiasporaFullReg"
            v-on:hideApprovePage="hideApprovePage"
        />

        <!-- import full without Prov AQ reg approval template here --> 
        <!-- <FullWithOutAQRegApproval
            v-if="code == showOtherApprovalPage && isDiasporaFullReg"
            v-bind:application="application"
            v-bind:privileges="privileges"
            v-bind:loadApplication="loadApplication"
            :expiryDate="expiryDate"
            :isProvisionalApp="isProvisionalApp"
            v-on:hideApprovePage="hideApprovePage"
        /> -->

        <!-- import Diaspora License approval template here --> 
        <DiasporaLicenseApproval
            v-if="code == 'diaspora-license' && showOtherApprovalPage"
            :application="application"
            :privileges="privileges"
            :loadApplication="loadApplication"
            v-on:hideApprovePage="hideApprovePage"
        />



        <div class="table-responsive" v-if="! showOtherApprovalPage">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>Registration Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 paddingLeft0">
                                    <h4 class="text-uppercase font-weight-light paddingTop13">
                                        {{ application.user_data.reg_prefix }} -
                                    </h4>
                                </div>
                                <div class="col-sm-5 paddingRight0">
                                    <base-input
                                        placeholder="Enter Registration Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="application.user_data.registration_no"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="application.user_data.registration_no == '' ||
                                application.user_data.registration_no == null"
                        >
                            Kindly enter a valid Registration Number,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody v-if="showLicenseYear">
                    <td width="30%">
                        <h4>Modify License Year:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input>
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="licenseYear"
                                            @change="displaySaveButton()"
                                        >
                                            <option  value="null" disabled>
                                                ---Select One---
                                            </option>
                                            <option
                                                v-for="(year, index) in years"
                                                :key="index"
                                                :value="year"
                                            >
                                                {{ year }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="licenseYear == '' || licenseYear == null"
                        >
                            Kindly enter the license year,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Application Expiry Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: true}"
                                            class="form-control datepicker"
                                            v-model="expiry_date"
                                            @input="displaySaveButton()"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="showApproveButton">
                    <td width="30%">
                        <h4>Certificates Generated:</h4>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4">
                                <form :action="printCertificateUrl" method="POST" target="_blank">
                                    <input type="hidden" name="id" :value="application.id" />
                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.certificate.id"
                                    />
                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <!-- <input
                                        type="hidden" name="valid_date"
                                        :value="application.valid_date"
                                    />

                                    <input
                                        v-if="showLicenseYear"
                                        type="hidden" name="license_year"
                                        :value="licenseYear"
                                    /> -->

                                    <base-button
                                        size="sm"
                                        type="info"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.certificate) }}
                                    </base-button>
                                </form>
                            </div>

                            <div
                                class="col-sm-4"
                                v-show="licenses && licenses.length > 0"
                                v-for="annual in licenses"
                                :key="annual.id"
                            >
                                <form :action="printCertificateUrl" method="POST" target="_blank">
                                    <input
                                        type="hidden" name="id"
                                        :value="annual.id"
                                    />
                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.license_certificate.certificate.id"
                                    />
                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <base-button
                                        size="sm"
                                        type="primary"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.license_certificate.certificate) }}
                                        {{ annual.license_year ? '(' + annual.license_year + ')' : '' }}
                                    </base-button>
                                </form>
                            </div>

                        </div>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody v-if="showSaveButton || showApproveButton || showBackButton">
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="primary"
                            :disabled="privileges && privileges.update == 'no'"
                            @click="updateProfile()"
                            v-if="showSaveButton && ! showBackButton"
                        >
                            <i class="fa fa-save"></i>
                            Save Changes
                        </base-button>

                        <base-button
                            size="lg"
                            type="success"
                            :disabled="privileges && privileges.update == 'no'"
                            @click="approveUserApplication(application.id)"
                            v-if="! showSaveButton && ! showBackButton && showApproveButton"
                        >
                            <i class="fa fa-check"></i>
                            Approve Application
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton && ! showSaveButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import ChangeOfNameApproval from './OtherApprovals/ChangeOfNameApproval';
  import AddQualificationApproval from './OtherApprovals/AddQualificationApproval';
  import GoodStandingApproval from './OtherApprovals/GoodStandingApproval';
  import LifeLicenseApproval from './OtherApprovals/LifeLicenseApproval';
  import EcisApproval from './OtherApprovals/EcisApproval';
  import TempRegApproval from './OtherApprovals/TempRegApproval';
  import CtcFullRegApproval from './OtherApprovals/CtcFullRegApproval';
  import ProvisionalApproval from './OtherApprovals/ProvisionalApproval';
  import FullWithOutAQRegApproval from './OtherApprovals/FullWithOutAQRegApproval';
  import DiasporaLicenseApproval from './OtherApprovals/DiasporaLicenseApproval.vue';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ApproveApplication',
    props: ['application', 'privileges', 'loadApplication', 'hideApprovePage', 'expiryDate', 'isProvisionalApp', 'isDiasporaFullReg'],
    components: {
        flatPicker,
        ChangeOfNameApproval,
        AddQualificationApproval,
        GoodStandingApproval,
        LifeLicenseApproval,
        EcisApproval,
        TempRegApproval,
        CtcFullRegApproval,
        ProvisionalApproval,
        FullWithOutAQRegApproval,
        DiasporaLicenseApproval
    },
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showSaveButton: true,
        showBackButton: false,
        licenseYear: null,
        expiry_date: null
      }
    },
    computed: {
        ...mapGetters(['printCertificateUrl', 'user']),
        showApproveButton() {
            return this.showSaveButton ? false : true;
        },
        years() {
            let startYear = moment().format('YYYY') -32;// start from 1900
            let currentYear = parseInt(moment().format('YYYY')) + 20;
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        },
        showLicenseYear() {
            return (this.code == 'annual-license') || (this.code == 'annual-license-old') ||
                (this.code == 'alt-annual-license') ||
                (this.application.license_certificate && this.application.license_certificate.annual) ?
                true : false;
        },
        showOtherApprovalPage() { //
            let validCodes = ['change-of-name', 'additional-pgq', 'cert-good-standing', 'life-license', 'prov-reg', 'eics-verification', 'limited-reg', 
                'limited-reg-long', 'ctc-full-reg', 'foreign-prov-reg', 'full-aq-reg', 'limited-reg-renewal', 'limited-reg-long-renewal', 
                'diaspora-license', 'full-aq-diaspora', 'additional-pgq-diaspora', 'change-of-name-diaspora'];// eslint-disable-next-line
            return (validCodes.includes(this.code));
        },
        licenses() {
            return this.application.license_certificate && this.application.license_certificate.annual ?
                        this.application.license_certificate.annual : [];
        }
    },
    methods: {
      ...mapActions(['updateUserData', 'approveApplication']),
        setData() {
            if(this.licenses && this.licenses.length > 0) {
                this.licenseYear = this.licenses[0].license_year;
            } else {
                this.licenseYear = this.application.license_year;
            }
            this.expiry_date = this.application.valid_date ? this.application.valid_date : this.expiryDate;
        },
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name.replace(',', '')+' ' : '';
            name += profile.last_name;
            return name;
        },
        getCurrentYear() {// get current date time
            let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
            return moment(date).format('YYYY');
        },
        getCertificateName(type) {
            return type ? type.title : 'M/A';
        },
        approveUserApplication(id) {// approve application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeApproveApiCall(id);// make api call
                }
            });
        },
        makeApproveApiCall(id) {// approve application
            let data = { 'id' : id, 'status' : 'approved', 'admin_id' : this.user.id };
            this.preloader();// show loading
            this.approveApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        updateProfile() {// update user data
            let data = {
                id : this.application.user_data_id,
                app_id : this.application.id,
                licenses : this.licenses,
                registration_no : this.application.user_data.registration_no,
                valid_date : this.expiry_date
            };
            if(data.licenses && data.licenses.length > 0) {// set modify licenses year
                data.licenses[0].license_year = this.licenseYear;
            } else if(this.showLicenseYear) {// for annual license
                data.license_year = this.licenseYear;
            }
            let check = this.validatedInputs(data); // validate
            if(check) {
                this.preloader();// show loading
                this.updateUserData(data).then((res) => {// update user data
                    if(res.status) {
                        this.showSaveButton = false
                        swal.fire('Profile Updated!', res.msg, 'success');
                        this.loadApplication(); // reload page
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = 'Server Failure!, Kindly reload the page...';
                    swal.fire('Error Occurred!', msg, 'error');
                });
            }
        },
        validatedInputs(data) {
            let record = { registration_no: null, valid_date: null };
            record.valid_date = (this.expiry_date == null || this.expiry_date == '') ?
                this.showInuptError('Expiry Date') : this.expiry_date;
            record.registration_no = (data.registration_no == null || data.registration_no == '') ?
                this.showInuptError('Registration Number') : data.registration_no;
            return this.isEmpty(record) ? true : false;
        },
        isEmpty(obj) {// check for empty field
            return ! Object.keys(obj).some(k => !obj[k]);
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        displaySaveButton() {
            this.showSaveButton = true;
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.setData();
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
