
<template>
    <div>

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">
                Additional Qualification Details
            </h3>
            <hr class="my-4" />
        </div>

        <div class="row" v-if="record">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Category</h4>
                        </td>
                        <td colspan="3">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.qualification && record.qualification.category ?
                                    record.qualification.category.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>

                    <tbody>
                        <td>
                            <h4>Qualification</h4>
                        </td>
                        <td colspan="3">
                            <h4 class="text-capitalize font-weight-light">
                                <span
                                    v-html="breakWord(record.qualification.course_title)"
                                    v-if="record.qualification"
                                ></span>
                                <span v-if="! record.qualification">N/A</span>
                            </h4>
                        </td>
                    </tbody>

                    <tbody>
                        <td>
                            <h4>School Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(record.school_name)"></span>
                            </h4>
                        </td>
                        <td>
                            <h4>Degree</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.degree ? record.degree : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>

                    <tbody>
                        <td>
                            <h4>Qualify Date</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.qualify_date | formatDate }}
                            </h4>
                        </td>
                        <td>
                            <h4>Issuing Institution</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.licencing_body }}
                            </h4>
                        </td>
                    </tbody>

                    <tbody>
                        <td>
                            <h4>Duration of Training <br>(In Weeks)</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.duration }}
                            </h4>
                        </td>
                        <td>
                            <h4>Notification Date</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.notification_date | formatDate }}
                            </h4>
                        </td>
                    </tbody>
                </table>

            </div>

            <div class="col-lg-12" v-if="documents && documents.length > 0">
                <div class="row">
                    <div
                        class="col-sm-4 text-center borderLine"
                        v-for="(document, index) in documents"
                        :key="index"
                    >
                        <br>
                        <h4 class="text-capitalize height-50"
                            v-if="document.hasFile"
                        >
                            {{ document.name }}
                        </h4>
                        <h4 class="text-capitalize height-50"
                            v-if="! document.hasFile"
                        >
                            {{ document.name }}
                        </h4>
                        <br>
                        <badge
                            v-if="! document.hasFile"
                            type="danger"
                        >
                            Not Uploaded
                        </badge>
                        <base-button
                            tag="a" type="primary"
                            size="sm"
                            v-if="document.hasFile"
                            :href="document.url"
                            class="active" role="button"
                            aria-pressed="true"
                            target="_blank"
                        >
                            <i class="fa fa-eye"></i> View File
                        </base-button>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-lg-12 text-center" v-if="! record">
            <base-alert type="danger">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No Additional Qualification Details
                </span>
            </base-alert>
        </div>
        <hr class="my-4" />

    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'AddQualification',
        props: ['record', 'docs', 'breakWord'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            documents() {
                return this.record.documents ? this.record.documents : this.docs;
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
